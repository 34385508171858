import React from 'react';

const AccountSettings = React.lazy(() => import('./AccountSettings'));

/**
 * Lazy loaded account settings
 */
const LazyAccountSettings = () => (
  <React.Suspense fallback={<div>Yüklənir...</div>}>
    <AccountSettings />
  </React.Suspense>
);

export default LazyAccountSettings;
