import React from 'react';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { NavLink } from 'react-router-dom';
import { AuthContextProps, withAuth } from 'react-oidc-context';

/**
 * Class for user drop-down menu.
 */
export class AuthorizedUserSection extends React.Component<{
  auth?: AuthContextProps;
}> {
  state = {
    navbarOpen: false,
  };
  constructor(props: any) {
    super(props);
    this.signOut = this.signOut.bind(this);
  }

  /** sign out. */
  async signOut() {
    await this.props.auth?.signoutRedirect();
  }

  render() {
    return (
      <UncontrolledDropdown nav>
        <DropdownToggle nav caret>
          <FontAwesomeIcon icon={faUser} className="me-2" />
          {this.props.auth?.user?.profile.name}
        </DropdownToggle>
        <DropdownMenu start="true">
          <NavLink to="/settings/account">
            <DropdownItem>Tənzimləmələr</DropdownItem>
          </NavLink>
          <DropdownItem divider />
          <DropdownItem onClick={this.signOut}>Çıxış</DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    );
  }
}

export default withAuth(AuthorizedUserSection);
