import React from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DefaultLayout } from '../../layouts/DefaultLayout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import { useAuth } from 'react-oidc-context';
import config from '../../config';

interface QuizBank {
  name: string;
  type: string;
  passScore: string;
  deadlineInMinutes: string;
  consumerKey: string;
  consumerSecret: string;
}

/**
 * Component for creating a quiz bank
 */
function CreateQuizBank() {
  const [consumerSecret, setConsumerSecret] = useState('password');
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const auth = useAuth();
  const navigate = useNavigate();
  const [formValues, setformValues] = useState({
    examName: '',
    examType: 'exam',
    passScore: '',
    deadlineInMinutes: '',
    consumerKey: '',
    consumerSecret: '',
  });

  const [formErrors, setFormErrors] = useState({
    examTypeError: '',
    examNameError: '',
    passScoreError: '',
    deadlineInMinutesError: '',
  });

  /**
   * handleChange
   */
  const handleChange = (event: any) => {
    const { name, value } = event.currentTarget;
    setformValues({ ...formValues, [name]: value });
  };

  /**
   * handlePassword
   */
  const handlePassword = () => {
    consumerSecret === 'password'
      ? setConsumerSecret('text')
      : setConsumerSecret('password');
  };

  /**
   * validateForm
   */
  const validateForm = (values: any) => {
    const errors = {
      examNameError: '',
      examTypeError: '',
      passScoreError: '',
      deadlineInMinutesError: '',
    };

    const regexNumbers = /^\d+$/;
    const regexLetters = /^[A-Za-z]+$/;

    if (values.examType === '') {
      errors.examTypeError = 'exam type  is required field!';
    } else if (!regexLetters.test(values.examType)) {
      errors.examTypeError = 'exam type cannot contain anything other than letters!';
    } else if (values.examType.length > 15) {
      errors.examTypeError = 'exam type cannot exceed 15 characters!';
    }
    if (values.examName === '') {
      errors.examNameError = 'Ad boş buraxıla bilməz!';
    }
    if (values.passScore === '') {
      errors.passScoreError = 'Keçid balı boş buraxıla bilməz!';
    } else if (!regexNumbers.test(values.passScore)) {
      errors.passScoreError = 'Keçid balı yanlız rəqəmlərdən ibarət olmalıdır!';
    } else if (values.passScore < 50) {
      errors.passScoreError = 'Keçid balı min 50 olmalıdır!';
    } else if (values.passScore > 99) {
      errors.passScoreError = 'Keçid balı max 99 olmalıdır!';
    }
    if (values.deadlineInMinutes === '') {
      errors.deadlineInMinutesError = 'Müddət boş buraxıla bilməz!';
    } else if (!regexNumbers.test(values.deadlineInMinutes)) {
      errors.deadlineInMinutesError = 'Müddət yanlız rəqəmlərdən ibarət olmalıdır!';
    } else if (values.deadlineInMinutes < 30) {
      errors.deadlineInMinutesError = 'Müddət min 30 olmalıdır!';
    } else if (values.deadlineInMinutes > 90) {
      errors.deadlineInMinutesError = 'Müddət max 90 olmalıdır!';
    }

    return errors;
  };

  /**
   * @returns id of created quiz bank, or `null`, if not successful
   */
  async function createQuizBank(quizBank: QuizBank): Promise<string | null> {
    let data: any;

    try {
      const response = await fetch(`${config.QUIZ_MANAGEMENT_API_URL}/quiz-banks`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${auth.user?.access_token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: quizBank.name,
          deadline_in_minutes: Number(quizBank.deadlineInMinutes),
          pass_score: Number(quizBank.passScore),
          group: quizBank.type,
          consumer_key: quizBank.consumerKey,
          consumer_secret: quizBank.consumerSecret,
        }),
      });

      if (!response.ok) {
        throw new Error(`${response.status} ${response.statusText}`);
      }

      data = await response.json();
    } catch (err) {
      return null;
    }

    return data.id;
  }
  /**
   * handleSubmit
   */
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setIsSubmitting(true);
    setIsSubmitted(false);
    const formErrors = validateForm(formValues);
    setFormErrors(formErrors);
    const errorFree = Object.values(formErrors).every((value) => value === '');

    setIsSubmitting(false);

    if (!errorFree) {
      return;
    }

    const newQuizBank: QuizBank = {
      name: formValues.examName,
      type: formValues.examType,
      passScore: formValues.passScore,
      deadlineInMinutes: formValues.deadlineInMinutes,
      consumerKey: formValues.consumerKey,
      consumerSecret: formValues.consumerSecret,
    };

    const createdQuizBankId = await createQuizBank(newQuizBank);

    setIsSubmitting(false);

    if (createdQuizBankId == null) {
      // TODO: Add a visual error
      return;
    }

    setIsSubmitted(true);
    setTimeout(() => setIsSubmitted(false), 2000);
  };
  /**
   * handleCancel
   */
  const handleCancel = () => {
    navigate('/quizbank');
  };
  return (
    <DefaultLayout>
      <h2>{formValues.examName === '' ? 'Yeni bank' : formValues.examName}</h2>
      <form onSubmit={handleSubmit}>
        <div className="quiz-form">
          <label htmlFor="examName">Adı:</label>

          <div className="d-flex flex-column">
            <input
              type="text"
              name="examName"
              value={formValues.examName}
              onChange={handleChange}
              id="examName"
              className="form-control"
            />
            {formErrors.examNameError ? (
              <p className="show-form-error">{formErrors.examNameError}</p>
            ) : (
              <p className="hide-form-error">Placehodler</p>
            )}
          </div>
        </div>

        <div className="quiz-form">
          <label htmlFor="examType">Tipi:</label>
          <select
            id="examType"
            value={formValues.examType}
            onChange={handleChange}
            name="examType"
            className="form-select"
          >
            <option value="exam">EXAM</option>
            <option value="train">TRAIN</option>
          </select>
        </div>

        <div className="quiz-form passScore">
          <label htmlFor="passScore">Keçid balı:</label>
          <div className="d-flex flex-column">
            <input
              type="text"
              name="passScore"
              id="passScore"
              className="form-control"
              value={formValues.passScore}
              onChange={handleChange}
            />
            {formErrors.passScoreError ? (
              <p className="show-form-error mr-n1">{formErrors.passScoreError}</p>
            ) : (
              <p className="hide-form-error">Placehodler</p>
            )}
          </div>
          <label htmlFor="deadlineInMinutes">Müddət:</label>

          <div className="d-flex flex-column">
            <input
              type="text"
              name="deadlineInMinutes"
              id="deadlineInMinutes"
              className="form-control"
              value={formValues.deadlineInMinutes}
              onChange={handleChange}
            />
            {formErrors.deadlineInMinutesError ? (
              <p className="show-form-error">{formErrors.deadlineInMinutesError}</p>
            ) : (
              <p className="hide-form-error">Placeholder</p>
            )}
          </div>
        </div>

        <div className="quiz-form">
          <label htmlFor="consumerKey">LTİ açar:</label>

          <input
            type="text"
            name="consumerKey"
            id="consumerKey"
            className="form-control"
            value={formValues.consumerKey}
            onChange={handleChange}
          />
        </div>
        <div className="quiz-form">
          <label htmlFor="consumerSecret">LTİ şifrə:</label>

          <input
            type={consumerSecret}
            name="consumerSecret"
            id="consumerSecret"
            className="form-control"
            value={formValues.consumerSecret}
            onChange={handleChange}
          />
          <span onClick={handlePassword} className="consumer-secret">
            {consumerSecret === 'password' ? (
              <FontAwesomeIcon icon={faEyeSlash} />
            ) : (
              <FontAwesomeIcon icon={faEye} />
            )}
          </span>
        </div>
        <div className="quiz-buttons d-flex">
          <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
            Yarat
          </button>
          <button className="btn btn-secondary" onClick={handleCancel}>
            İmtina
          </button>
        </div>
      </form>
      {isSubmitted && (
        <Stack sx={{ width: '100%', marginTop: '10px' }}>
          <Alert>Quizbank əlavə olundu!</Alert>
        </Stack>
      )}
    </DefaultLayout>
  );
}

export default CreateQuizBank;
