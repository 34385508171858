import { WebStorageStateStore } from 'oidc-client-ts';
import { AuthProviderProps } from 'react-oidc-context';
import config from '../config';

export default {
  authority: config.AUTH_URL,
  client_id: config.AUTH_CLIENT_ID,
  redirect_uri: config.ADMIN_UI_URL + '/callback',
  post_logout_redirect_uri: config.ADMIN_UI_URL,
  onSigninCallback: () => {
    // Erase sensitive authorization data, sent as URL query params after logging in via Keycloak.
    // It works by replacing the page, kept in history stack of the current tab, with the same page, but without query params.
    window.history.replaceState({}, document.title, window.location.pathname);
  },
  userStore: new WebStorageStateStore({ store: window.localStorage }), // persist session across browser startups
} as AuthProviderProps;
