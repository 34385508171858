import React from 'react';

const Dashboard = React.lazy(() => import('./Dashboard'));

/**
 * Lazy loaded dashboard
 */
const LazyDashboard = () => (
  <React.Suspense fallback={<div>Yüklənir...</div>}>
    <Dashboard />
  </React.Suspense>
);

export default LazyDashboard;
