import React from 'react';

const Quizbank = React.lazy(() => import('./Quizbank'));

/**
 * Lazy loaded user dashboard
 */
const LazyQuizboard = () => (
  <React.Suspense fallback={<div>Yüklənir...</div>}>
    <Quizbank />
  </React.Suspense>
);

export default LazyQuizboard;
