import React from 'react';

import hljs from 'highlight.js';
import { marked as Marked } from 'marked';
import Katex from 'katex';

import 'katex/dist/katex.css';

import 'highlight.js/styles/github.css';

interface DocumentRendererProps {
  src: string;
  className?: string;
}

/**
 * Custom document renderer class to render Markdown documents.
 * Included customizations for OzunOyren.
 */
export class DocumentRenderer extends React.Component<DocumentRendererProps> {
  constructor(props: DocumentRendererProps) {
    super(props);

    Marked.setOptions({
      renderer: new CustomRenderer(),
      highlight: this.codeHighlighter,
    });

    Marked.use({ extensions: [new LatexTokenBlock(), new LatexTokenInline()] });
  }

  /**
   * Accepts the source code and highlights it based on the language.
   * Returns highlighted text in HTML.
   *
   * @param code - raw source code
   * @param lang - programming language
   * @returns highlighted text in HTML
   */
  codeHighlighter(code: string, lang: string) {
    if (!lang) {
      return code;
    }

    if (lang === 'nohighlight') {
      return code;
    }

    return hljs.highlight(lang, code).value;
  }

  render() {
    const markedData = Marked.parse(this.props.src);
    return (
      <div
        className={this.props.className}
        dangerouslySetInnerHTML={{ __html: markedData }}
      />
    );
  }
}

/**
 * Custom token for marked to define inline LaTeX code.
 * Converts the LaTeX code into HTML.
 */
class LatexTokenInline {
  name = 'latex';
  level = 'inline';

  /**
   * Custom renderer for 'latex' token type.
   */
  renderer(token: Marked.Tokens.Generic) {
    const htmlText = Katex.renderToString(token.formula, {
      displayMode: false,
      throwOnError: false,
      output: 'html',
    });
    return `<span class="latex">${htmlText}</span>`;
  }

  /**
   * Start position of the latex block.
   */
  start(src: string) {
    return src.match(/\$[^\$]/)?.index ?? -1;
  }

  /**
   * Tokenizer logic for latex block.
   */
  tokenizer(src: string) {
    const pattern = /^\$([^\$]+)\$/;
    const match = pattern.exec(src);
    const result = match
      ? { type: 'latex', raw: match[0], formula: match[1] }
      : undefined;
    return result;
  }
}

/**
 * Custom token for marked to define block LaTeX code.
 * Converts the LaTeX code into HTML.
 *
 * Note: Basically this is a duplicate of the previous one.
 * Then why we can't use the same class and use behaviour based on parameters?
 * Some weirdo used "this" keyword for the parameter name in renderer and tokenizer
 * methods. So I was unable to find any alternative way to access member variables.
 */
class LatexTokenBlock {
  name = 'latex-block';
  level = 'block';

  /**
   * Custom renderer for 'latex' token type.
   */
  renderer(token: Marked.Tokens.Generic) {
    const htmlText = Katex.renderToString(token.formula, {
      displayMode: true,
      throwOnError: false,
      output: 'html',
    });
    return `<p class="latex-block">${htmlText}</p>`;
  }

  /**
   * Start position of the latex block.
   */
  start(src: string) {
    return src.match(/\$\$[^\$]/)?.index ?? -1;
  }

  /**
   * Tokenizer logic for latex block.
   */
  tokenizer(src: string) {
    const pattern = /^\$\$([^\$]+)\$\$/;
    const match = pattern.exec(src);
    const result = match
      ? { type: 'latex-block', raw: match[0], formula: match[1] }
      : undefined;
    return result;
  }
}

/**
 * Custom renderer to render specific elements of Markdown and add new features.
 */
class CustomRenderer extends Marked.Renderer {
  private escapeTest = /[&<>"']/;
  private escapeReplace = /[&<>"']/g;
  private escapeTestNoEncode = /[<>"']|&(?!#?\w+;)/;
  private escapeReplaceNoEncode = /[<>"']|&(?!#?\w+;)/g;
  private escapeReplacements: Record<string, string> = {
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;',
    '"': '&quot;',
    "'": '&#39;',
  };
  private getEscapeReplacement = (ch: string) => this.escapeReplacements[ch];

  /**
   * Function to escape certain html elements.
   * Original source: https://github.com/markedjs/marked/blob/master/src/helpers.js#L4-L28
   */
  private escape(html: string, encode: boolean) {
    if (encode) {
      if (this.escapeTest.test(html)) {
        return html.replace(this.escapeReplace, this.getEscapeReplacement);
      }
    } else {
      if (this.escapeTestNoEncode.test(html)) {
        return html.replace(this.escapeReplaceNoEncode, this.getEscapeReplacement);
      }
    }

    return html;
  }

  /**
   * Modified version of code renderer for markdown.
   * @param code - source code
   * @param infostring - string to specify language
   * @param escaped - true if need to escape
   * @returns rendered and highlighed source code
   */
  code(code: string, infostring: string, escaped: boolean) {
    let lang = '';

    const filteredInfoString = (infostring || '').match(/\S*/);
    if (filteredInfoString) {
      lang = filteredInfoString[0];
    }

    if (this.options.highlight) {
      const out = this.options.highlight(code, lang);
      if (out != null && out !== code) {
        escaped = true;
        code = out;
      }
    }

    code = code.replace(/\n$/, '') + '\n';

    if (!lang) {
      return '<pre>' + (escaped ? code : this.escape(code, true)) + '</pre>\n';
    }

    return (
      '<pre class="' +
      this.options.langPrefix +
      this.escape(lang, true) +
      '">' +
      (escaped ? code : this.escape(code, true)) +
      '</pre>\n'
    );
  }
}
