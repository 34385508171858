import React from 'react';
import { useAuth } from 'react-oidc-context';
import { Navigate } from 'react-router-dom';

const Callback = () => {
  const auth = useAuth();

  if (auth.isLoading) {
    return (
      <div
        style={{ height: '100vh' }}
        className="d-flex justify-content-center align-items-center"
      >
        <h1>Avtorizasiya edilir...</h1>
      </div>
    );
  }

  return <Navigate to="/" />;
};

export default Callback;
