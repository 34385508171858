import React, { useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import '../styles.css';
import { DefaultLayout } from '../../../layouts/DefaultLayout';
import { useNavigate } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import { DocumentRenderer } from '../../../components/DocumentRenderer';
import config from '../../../config';
import { useAuth } from 'react-oidc-context';

/**
 * Component for creating a question for a quiz bank
 */
function CreateQuestion() {
  const { id: quizBankId } = useParams();
  const auth = useAuth();
  const navigate = useNavigate();
  const [question, setQuestion] = useState('');
  const [questionType, setQuestionType] = useState('');
  const [isVisible, setIsVisible] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [answerText, setAnswerText]: any = useState([]);
  const [answers, setAnswers]: any = useState([{ answer: '' }]);
  let [checkedValues, setCheckedValues]: any = useState([]);
  const ref: any = useRef(null);
  /**
   *change question
   */
  const handleChange = (e: any) => {
    setQuestion(e.target.value);
  };
  /**
   *change type of question
   */
  const handleTypeChange = (e: any) => {
    setQuestionType(e.target.value);
    setAnswerText(answers.map((item: any) => item.answer));
    let max = 0;
    if (checkedValues.length > 1) {
      for (let i = 0; i < checkedValues.length; i++) {
        const index = answerText.indexOf(checkedValues[i]);
        if (index >= max) {
          max = index;
        }
      }
      checkedValues = checkedValues.filter((item: any) => item === answerText[max]);
      setCheckedValues(checkedValues);
    }
  };
  /**
   *add answer
   */
  const handleAddAnswer = () => {
    setAnswers([...answers, { answer: '' }]);
    if (answers.length === 0) {
      setIsVisible(false);
    }
  };
  const handleDeleteAnswer = () => {
    setAnswers(answers.slice(0, -1));
    if (answers.length === 1) {
      setIsVisible(true);
    }
  };

  /**
   * @returns id of created question, or `null`, if not successful
   */
  async function createQuestion(): Promise<string | null> {
    let data: any;

    try {
      const response = await fetch(
        `${config.QUIZ_MANAGEMENT_API_URL}/quiz-banks/${quizBankId}/questions`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${auth.user?.access_token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            content: `${question}`,
            options: answers.map((answer: any) => ({
              text: answer.answer,
              is_correct: checkedValues.includes(answer.answer),
            })),
          }),
        },
      );

      if (!response.ok) {
        throw new Error(`${response.status} ${response.statusText}`);
      }

      data = await response.json();
    } catch (err) {
      return null;
    }

    return data.id;
  }

  /**
   *handleSubmit
   */
  const handleSubmit = async (e: any, index: any) => {
    e.preventDefault();

    const { name, value }: any = ref.current;
    const list = [...answers];
    list[index][name] = value;
    setAnswers(list);

    const createdQuestionId = await createQuestion();

    if (createdQuestionId == null) {
      // TODO: Add a visual error
      return;
    }

    setIsSubmitted(true);

    setTimeout(() => {
      setIsSubmitted(false);
    }, 2000);

    setQuestion('');
    setQuestionType('');
    setIsVisible(false);
    setAnswerText([]);
    setAnswers([{ answer: '' }]);
    setCheckedValues([]);
  };

  /**
   *change answer
   */
  const handleAnswerChange = (e: any, index: any) => {
    const { name, value } = e.target;
    const list = [...answers];
    list[index][name] = value;
    setAnswers(list);
  };

  /**
   *handle correct answer
   */
  const handleCorrectAnswerChange = (e: any) => {
    const value = e.target.value;
    const checked = e.target.checked;
    if (e.target.type == 'checkbox') {
      if (checked) {
        checkedValues.push(value);
        // setCheckedValues([...checkedValues, value]);
      } else {
        setCheckedValues(checkedValues.filter((e: any) => e !== value));
      }
    } else if (e.target.type == 'radio') {
      if (checked) {
        setCheckedValues([value]);
      } else {
        setCheckedValues(checkedValues.filter((e: any) => e !== value));
      }
    }
  };

  const handleCancel = () => {
    navigate(`/quizbank/${quizBankId}/questions`);
  };

  return (
    <>
      <DefaultLayout>
        <div className="mx-3">
          <h3 className="mb-3">Sualın yaradılması</h3>
          <div className="d-flex justify-content-between align-items-center">
            <form className="question-form">
              <div className="quiz-form">
                <label>Sual növü:</label>
                <select
                  value={questionType}
                  onChange={handleTypeChange}
                  className="form-select"
                  name="questionType"
                >
                  <option value="single">Tək variantlı</option>
                  <option value="multiple">Çox variantlı</option>
                </select>
              </div>
              <br />
              <div className="d-flex justify-content-between">
                <div>
                  <label>Sual:</label>
                  <br />
                  <textarea
                    value={question}
                    required
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    className="form-control"
                    rows={10}
                    cols={55}
                  />
                </div>
                <div className="markdown mt-4 form-control">
                  <DocumentRenderer src={question ?? ''} />
                </div>
              </div>
              <br />
              {isVisible && (
                <button
                  className="btn-add-answer"
                  type="button"
                  onClick={handleAddAnswer}
                >
                  Cavab əlavə et
                </button>
              )}
              {answers.map((answer: any, index: any) => (
                <div key={index} id={index}>
                  <div className="d-flex justify-content-between">
                    <div className="mt-4">
                      <label>Cavab {index + 1}</label>
                      <br />
                      <textarea
                        className="form-control"
                        ref={ref}
                        value={answer.answer}
                        name="answer"
                        required
                        onChange={(e) => handleAnswerChange(e, index)}
                        rows={6}
                        cols={55}
                      />
                    </div>
                    <div className="d-flex flex-column justify-content-end align-items-end">
                      <div
                        className="correct-answer d-flex justify-content-center align-items-center mb-2"
                        id={index}
                      >
                        <input
                          ref={ref}
                          type={questionType === 'multiple' ? 'checkbox' : 'radio'}
                          name="correctAnswer"
                          value={answer.answer}
                          onClick={handleCorrectAnswerChange}
                        />
                        <label>Düzgün cavab</label>
                        <button
                          className="btn btn-danger"
                          type="button"
                          onClick={handleDeleteAnswer}
                        >
                          <span>Cavabı sil</span>
                        </button>
                      </div>
                      <div className="markdown md-answer form-control">
                        <DocumentRenderer src={answer.answer ?? ''} />
                      </div>
                    </div>
                  </div>

                  <br />
                  {answers.length - 1 === index && (
                    <>
                      {' '}
                      <button
                        className="btn btn-primary"
                        type="button"
                        onClick={handleAddAnswer}
                      >
                        <span>Yeni Cavab</span>
                      </button>
                    </>
                  )}
                </div>
              ))}
              <div className="quiz-buttons d-flex question-btn">
                <button
                  className="btn btn-primary"
                  onClick={(e) => {
                    handleSubmit(e, ref.current?.parentElement.getAttribute('id'));
                  }}
                >
                  Yadda saxla
                </button>
                <button className="btn btn-secondary" onClick={handleCancel}>
                  İmtina
                </button>
              </div>
            </form>
            <br />
          </div>
          {isSubmitted && (
            <Stack sx={{ width: '100%', marginTop: '10px' }}>
              <Alert>Sual əlavə olundu!</Alert>
            </Stack>
          )}
        </div>
      </DefaultLayout>
    </>
  );
}
export default CreateQuestion;
