import React from 'react';
import { NavLink } from 'react-router-dom';

import { Navbar, NavbarToggler, Collapse, Nav } from 'reactstrap';

import AuthorizedUserSection from './AuthorizedUserSection';

/**
 * Saytın damı
 * Logo və ümumi naviqasiya panelləri burda yerləşər
 */
export class HeaderBar extends React.Component {
  state = {
    navbarOpen: false,
  };

  constructor(props: any) {
    super(props);
    this.toggle = this.toggle.bind(this);
  }

  /**
   * Toggle the navbar.
   */
  toggle() {
    this.setState({
      navbarOpen: !this.state.navbarOpen,
    });
  }

  render() {
    return (
      <div className="header py-2">
        <div className="container-fluid">
          <Navbar light expand="md" className="p-0">
            <NavLink to="/" className="navbar-brand">
              <p>Quizlərin idarəolunması paneli</p>
            </NavLink>
            <NavbarToggler onClick={this.toggle} />
            <Collapse isOpen={this.state.navbarOpen} navbar>
              <Nav className="ms-auto" navbar>
                <AuthorizedUserSection />
              </Nav>
            </Collapse>
          </Navbar>
        </div>
      </div>
    );
  }
}
