const config = {
  QUIZ_MANAGEMENT_API_URL: process.env.REACT_APP_QUIZ_MANAGEMENT_API_URL,
  ADMIN_UI_URL: process.env.REACT_APP_ADMIN_UI_URL,
  AUTH_URL: process.env.REACT_APP_AUTH_URL,
  AUTH_CLIENT_ID: process.env.REACT_APP_AUTH_CLIENT_ID,
};

export default {
  ...config,
};
