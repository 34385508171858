import React from 'react';
import { NavLink } from 'react-router-dom';
import { Link } from 'react-router-dom';
export class NavigationBar extends React.Component<{ content: JSX.Element }> {
  render() {
    return (
      <>
        <div className="sidebar d-flex justify-content-between flex-column">
          <div>
            <Link to="/">
              <img width="100%" height="42px" src="/logo.svg" alt="OzunOyren logo" />
            </Link>
            {this.props.content ?? (
              <ul className="sidebar-menu">
                <li>
                  <NavLink to="/">Əsas səhifə</NavLink>
                </li>
                <li>
                  <NavLink to="/quizbank">Sual bankları</NavLink>
                </li>
              </ul>
            )}
          </div>
          <span className="mb-1 text-small footer-text mx-auto">
            © 2021 ÖzünÖyrən. Azərbaycan istehsalı.
          </span>
        </div>
      </>
    );
  }
}
