import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from 'react-oidc-context';

/**
 * Qorunan routelar üçün məntiq
 */
const PrivateRoute = () => {
  const auth = useAuth();

  if (auth.isLoading) {
    return (
      <div
        style={{ height: '100vh' }}
        className="d-flex justify-content-center align-items-center"
      >
        <h1>Avtorizasiya edilir...</h1>
      </div>
    );
  }

  if (!auth.isAuthenticated) {
    return <Navigate to="/signin" />;
  }

  return <Outlet />;
};

export default PrivateRoute;
