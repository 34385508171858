import React, { useState, useEffect } from 'react';
import { IconButton } from '@mui/material';
import { withRouter } from '../../../utils/withRouter';
import { Link, useParams } from 'react-router-dom';
import { DefaultLayout } from '../../../layouts/DefaultLayout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPen } from '@fortawesome/free-solid-svg-icons';
import { DataGrid } from '@mui/x-data-grid';
import config from '../../../config';
import { useAuth } from 'react-oidc-context';

type QuestionsList = {
  id: string;
  question: string;
  update: string;
  delete: string;
};

/**
 * Component for listing and managing questions of a quiz bank
 */
function Question() {
  const { id: quizBankId } = useParams();
  const auth = useAuth();
  const [quizbankName, setQuizbankName] = useState('');
  const [questions, setQuestions] = useState<QuestionsList[]>([]);
  const headers = [
    { field: 'id', headerName: 'ID', width: 120 },
    { field: 'content', headerName: 'Sual', width: 820 },
    {
      field: 'update',
      headerName: '',
      width: 150,
      renderCell: (params: any) => (
        <>
          <Link
            to={`/quizbank/${quizBankId}/questions/${params.row.id}`}
            state={{ questions: params.row }}
          >
            <IconButton>
              <FontAwesomeIcon icon={faPen} />
            </IconButton>
          </Link>
          <IconButton
            onClick={async () => {
              console.log(params.row);
              if (window.confirm('Are you sure you want to delete?')) {
                const selectedQuestionId = params.row.id;

                const isDeleted = await deleteSingleQuestion(selectedQuestionId);

                if (!isDeleted) {
                  // TODO: Integrate with a visual error
                  return;
                }

                const filteredQuestions = questions.filter(
                  (question) => question.id !== selectedQuestionId,
                );
                setQuestions(filteredQuestions);
              }
            }}
          >
            <FontAwesomeIcon icon={faTrash} />
          </IconButton>
        </>
      ),
    },
  ];

  const getQuizBank = async () => {
    let data: any;

    try {
      const response = await fetch(
        `${config.QUIZ_MANAGEMENT_API_URL}/quiz-banks/${quizBankId}`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${auth.user?.access_token}`,
          },
        },
      );

      if (!response.ok) {
        throw new Error(`${response.status} ${response.statusText}`);
      }

      data = await response.json();
    } catch (err) {
      // TODO: Add a visual error
      return;
    }

    return data;
  };

  const getQuestions = async (): Promise<any[]> => {
    let data: any;

    try {
      const response = await fetch(
        `${config.QUIZ_MANAGEMENT_API_URL}/quiz-banks/${quizBankId}/questions`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${auth.user?.access_token}`,
          },
        },
      );

      if (!response.ok) {
        throw new Error(`${response.status} ${response.statusText}`);
      }

      data = await response.json();

      if (data.items == null || !Array.isArray(data.items)) {
        throw new Error('Invalid data');
      }
    } catch (err) {
      return []; // TODO: Replace with a visual error
    }

    return data.items;
  };

  const deleteSingleQuestion = async (questionId: string): Promise<boolean> => {
    try {
      const response = await fetch(
        `${config.QUIZ_MANAGEMENT_API_URL}/quiz-banks/${quizBankId}/questions/${questionId}`,
        {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${auth.user?.access_token}`,
          },
        },
      );

      if (!response.ok) {
        throw new Error(`${response.status} ${response.statusText}`);
      }

      return true;
    } catch (err) {
      return false;
    }
  };

  useEffect(() => {
    getQuestions().then((questions) => {
      setQuestions(questions);
    });
    getQuizBank().then((quizBank) => {
      setQuizbankName(quizBank.name);
    });
  }, []);
  return (
    <DefaultLayout>
      <div className="quizboard-container">
        <h3 className="mb-3">{quizbankName}</h3>
        <div className="d-flex justify-content-end">
          <Link to={`/quizbank/${quizBankId}/questions/create`}>
            <button className="btn btn-primary"> Yeni sual</button>
          </Link>
        </div>
        <br />
        <br />
        {questions.length > 0 ? (
          <>
            <DataGrid
              rows={questions}
              columns={headers}
              pageSize={10}
              style={{ height: 700 }}
            />
            <br />
            <div className="d-flex justify-content-end">
              <Link to={`/quizbank/${quizBankId}/questions/create`}>
                <button className="btn btn-primary"> Yeni sual</button>
              </Link>
            </div>
          </>
        ) : (
          <div className="no-question">Sual mövcud deyil</div>
        )}
      </div>
    </DefaultLayout>
  );
}

export default withRouter(Question);
