import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import AccountSettings from './pages/AccountSettings';
import SignInForm from './pages/SingIn';
import Dashboard from './pages/Dashboard';
import Quizbank from './pages/Quizbank';
import CreateQuizbank from './pages/Quizbank/CreateQuizbank';
import UpdateQuizbank from './pages/Quizbank/UpdateQuizbank';
import Question from './pages/Quizbank/Questions/Question';
import CreateQuestion from './pages/Quizbank/Questions/CreateQuestion';
import UpdateQuestion from './pages/Quizbank/Questions/UpdateQuestion';
import './assets/bootstrap/bootstrap.scss';
import './assets/scss/style.scss';
import Callback from './pages/Callback/Callback';

function App() {
  return (
    <Router>
      <Routes>
        {/* Public routes */}
        <Route path="/signin" element={<SignInForm />} />
        <Route path="/callback" element={<Callback />} />

        {/* Private routes */}
        <Route path="/settings/account" element={<PrivateRoute />}>
          <Route path="/settings/account" element={<AccountSettings />} />
        </Route>
        <Route path="/quizbank/*" element={<PrivateRoute />}>
          <Route path="/quizbank/*" element={<Quizbank />} />
        </Route>
        <Route path="/quizbank/new" element={<PrivateRoute />}>
          <Route path="/quizbank/new" element={<CreateQuizbank />} />
        </Route>
        <Route path="/quizbank/:id" element={<PrivateRoute />}>
          <Route path="/quizbank/:id" element={<UpdateQuizbank />} />
        </Route>
        <Route path="/quizbank/:id/questions" element={<PrivateRoute />}>
          <Route path="/quizbank/:id/questions" element={<Question />} />
        </Route>
        <Route path="/quizbank/:id/questions/create" element={<PrivateRoute />}>
          <Route
            path="/quizbank/:id/questions/create"
            element={<CreateQuestion />}
          />
        </Route>
        <Route
          path="/quizbank/:quizBankId/questions/:questionId"
          element={<PrivateRoute />}
        >
          <Route
            path="/quizbank/:quizBankId/questions/:questionId"
            element={<UpdateQuestion />}
          />
        </Route>
        {/* Default route */}
        <Route path="/" element={<PrivateRoute />}>
          <Route path="/" element={<Dashboard />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
