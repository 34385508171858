import React from 'react';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { DefaultLayout } from '../../layouts/DefaultLayout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import { useAuth } from 'react-oidc-context';
import config from '../../config';

interface QuizBank {
  name: string;
  type: string;
  passScore: number;
  deadlineInMinutes: number;
  consumerKey: string;
  consumerSecret: string;
  consumerUrl: string;
}

interface Quizbank {
  name: string;
  group: string;
  pass_score: number;
  deadline_in_minutes: number;
  consumer_key: string;
  consumer_secret: string;
  consumer_url: string;
}

/**
 * Component for updating a quiz bank
 */
function UpdateQuizBank() {
  const { id: quizBankId } = useParams();
  const auth = useAuth();
  const navigate = useNavigate();
  const [quizbanks, setQuizbanks] = useState<Quizbank>({
    name: '',
    group: '',
    pass_score: 0,
    deadline_in_minutes: 0,
    consumer_key: '',
    consumer_secret: '',
    consumer_url: '',
  });
  // const [consumerSecret, setConsumerSecret] = useState('text');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [copied, setCopied] = useState('');
  const [copiedSecret, setCopiedSecret] = useState('');
  const [formErrors, setFormErrors] = useState({
    examTypeError: '',
    examNameError: '',
    passScoreError: '',
    deadlineInMinutesError: '',
  });

  const getQuizBank = async () => {
    let data: any;

    try {
      const response = await fetch(
        `${config.QUIZ_MANAGEMENT_API_URL}/quiz-banks/${quizBankId}`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${auth.user?.access_token}`,
          },
        },
      );

      if (!response.ok) {
        throw new Error(`${response.status} ${response.statusText}`);
      }

      data = await response.json();
    } catch (err) {
      // TODO: Add a visual error
      return;
    }

    setQuizbanks({
      name: data.name,
      group: 'exam', // TODO: delete this property everywhere, once it's confirmed that it's not needed
      pass_score: data.pass_score,
      deadline_in_minutes: data.deadline_in_minutes,
      // TODO: Add question_count_in_session
      consumer_key: data.consumer_key,
      consumer_secret: data.consumer_secret,
      consumer_url: `https://surf.cantyquizservice.dev.ozunoyren.org/session/ces/${quizBankId}`,
    });
  };

  useEffect(() => {
    getQuizBank();
  }, []);
  /**
   * handleChange
   */
  const handleChange = (event: any) => {
    const { name, value } = event.currentTarget;
    setQuizbanks({ ...quizbanks, [name]: value });
  };

  /**
   * handlePassword
   
  const handlePassword = () 
    consumerSecret === 'password'
      ? setConsumerSecret('text')
      : setConsumerSecret('password');
  ;
  */
  /**
   * handleCopy
   */
  const handleCopy = (value: string, type: 'secret' | 'url') => {
    navigator.clipboard.writeText(value);

    if (type === 'secret') {
      setCopiedSecret('copied');
      setTimeout(() => {
        setCopiedSecret('');
      }, 1000);
    } else if (type === 'url') {
      setCopied('copied');
      setTimeout(() => {
        setCopied('');
      }, 1000);
    }
  };
  /**
   * validateForm
   */
  const validateForm = (values: any) => {
    const errors = {
      examNameError: '',
      examTypeError: '',
      passScoreError: '',
      deadlineInMinutesError: '',
    };

    const regexNumbers = /^\d+$/;
    const regexLetters = /^[A-Za-z]+$/;

    if (values.group === '') {
      errors.examTypeError = 'exam type  is required field!';
    } else if (!regexLetters.test(values.group)) {
      errors.examTypeError = 'exam type cannot contain anything other than letters!';
    } else if (values.group.length > 15) {
      errors.examTypeError = 'exam type cannot exceed 15 characters!';
    }
    if (values.name === '') {
      errors.examNameError = 'Ad boş buraxıla bilməz!';
    }
    if (values.pass_score === '') {
      errors.passScoreError = 'Keçid balı boş buraxıla bilməz!';
    } else if (!regexNumbers.test(values.pass_score)) {
      errors.passScoreError = 'Keçid balı yanlız rəqəmlərdən ibarət olmalıdır!';
    } else if (values.pass_score < 50) {
      errors.passScoreError = 'Keçid balı min 50 simvol olmalıdır!';
    } else if (values.pass_score > 99) {
      errors.passScoreError = 'Keçid balı max 99 simvol olmalıdır!';
    }
    if (values.deadline_in_minutes === '') {
      errors.deadlineInMinutesError = 'Müddət boş buraxıla bilməz!';
    } else if (!regexNumbers.test(values.deadline_in_minutes)) {
      errors.deadlineInMinutesError = 'Müddət yanlız rəqəmlərdən ibarət olmalıdır!';
    } else if (values.deadline_in_minutes < 30) {
      errors.deadlineInMinutesError = 'Müddət min 30 simvol olmalıdır!';
    } else if (values.deadline_in_minutes > 90) {
      errors.deadlineInMinutesError = 'Müddət max 90 simvol olmalıdır!';
    }

    return errors;
  };

  async function updateQuizBank(quizBank: QuizBank): Promise<boolean> {
    try {
      const response = await fetch(
        `${config.QUIZ_MANAGEMENT_API_URL}/quiz-banks/${quizBankId}`,
        {
          method: 'PATCH',
          headers: {
            Authorization: `Bearer ${auth.user?.access_token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            name: quizBank.name,
            deadline_in_minutes: Number(quizBank.deadlineInMinutes),
            pass_score: Number(quizBank.passScore),
            group: quizBank.type,
            consumer_key: quizBank.consumerKey,
            consumer_secret: quizBank.consumerSecret,
          }),
        },
      );

      if (!response.ok) {
        throw new Error(`${response.status} ${response.statusText}`);
      }

      return true;
    } catch (err) {
      return false;
    }
  }

  /**
   * handleSubmit
   */
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setIsSubmitted(false);

    const formErrors = validateForm(quizbanks);
    setFormErrors(formErrors);
    const errorFree = Object.values(formErrors).every((value) => value === '');

    if (errorFree) {
      const quizBank: QuizBank = {
        name: quizbanks.name,
        type: quizbanks.group,
        passScore: quizbanks.pass_score,
        deadlineInMinutes: quizbanks.deadline_in_minutes,
        consumerKey: quizbanks.consumer_key,
        consumerSecret: quizbanks.consumer_secret,
        consumerUrl: quizbanks.consumer_url,
      };

      const isUpdated = await updateQuizBank(quizBank);

      if (!isUpdated) {
        // TODO: Add a visual error
        return;
      }

      setIsSubmitted(true);
    }
  };
  /**
   * handleCancel
   */
  const handleCancel = () => {
    navigate('/quizbank');
  };
  return (
    <DefaultLayout>
      <h2>{quizbanks.name === '' ? 'Yeni bank' : quizbanks.name}</h2>
      <form onSubmit={handleSubmit}>
        <div className="quiz-form">
          <label htmlFor="name">Adı:</label>

          <div className="d-flex flex-column">
            <input
              type="text"
              name="name"
              value={quizbanks.name}
              onChange={handleChange}
              id="name"
              className="form-control"
            />
            {formErrors.examNameError ? (
              <p className="show-form-error">{formErrors.examNameError}</p>
            ) : (
              <p className="hide-form-error">Placehodler</p>
            )}
          </div>
        </div>

        <div className="quiz-form">
          <label htmlFor="group">Tipi:</label>
          <select
            id="group"
            value={quizbanks.group}
            onChange={handleChange}
            name="group"
            className="form-select"
          >
            <option value="exam">EXAM</option>
            <option value="train">TRAIN</option>
          </select>
        </div>

        <div className="quiz-form passScore">
          <label htmlFor="pass_score">Keçid balı:</label>
          <div className="d-flex flex-column">
            <input
              type="text"
              name="pass_score"
              id="pass_score"
              className="form-control"
              value={quizbanks.pass_score}
              onChange={handleChange}
            />
            {formErrors.passScoreError ? (
              <p className="show-form-error mr-n1">{formErrors.passScoreError}</p>
            ) : (
              <p className="hide-form-error">Placehodler</p>
            )}
          </div>
          <label htmlFor="deadline_in_minutes">Müddət:</label>

          <div className="d-flex flex-column">
            <input
              type="text"
              name="deadline_in_minutes"
              id="deadline_in_minutes"
              className="form-control"
              value={quizbanks.deadline_in_minutes}
              onChange={handleChange}
            />
            {formErrors.deadlineInMinutesError ? (
              <p className="show-form-error">{formErrors.deadlineInMinutesError}</p>
            ) : (
              <p className="hide-form-error">Placeholder</p>
            )}
          </div>
        </div>

        <div className="quiz-form">
          <label htmlFor="consumer_key">LTİ açar:</label>

          <input
            type="text"
            name="consumer_key"
            id="consumer_key"
            className="form-control"
            value={quizbanks.consumer_key}
            onChange={handleChange}
          />
        </div>
        <div className="quiz-form">
          <label htmlFor="consumer_secret">LTİ şifrə:</label>

          <input
            type="text"
            name="consumer_secret"
            id="consumer_secret"
            className="form-control"
            value={quizbanks.consumer_secret}
            onChange={handleChange}
          />
          <span
            onClick={() => handleCopy(quizbanks.consumer_secret, 'secret')}
            className="consumer-secret"
          >
            {' '}
            {/* ... */}
            <FontAwesomeIcon icon={faCopy} />
            <p className="copy-url">{copiedSecret}</p>
          </span>
        </div>
        <div className="quiz-form">
          <label htmlFor="consumer_url">LTİ url:</label>
          <input
            type="consumer_url"
            name="consumer_url"
            id="consumer_url"
            className="form-control"
            value={quizbanks.consumer_url}
            readOnly
          />
          <span
            onClick={() => handleCopy(quizbanks.consumer_url, 'url')}
            className="consumer-secret"
          >
            {' '}
            {/* ... */}
            <FontAwesomeIcon icon={faCopy} />
            <p className="copy-url">{copied}</p>
          </span>
        </div>
        <div className="quiz-buttons d-flex">
          <button type="submit" className="btn btn-primary">
            Yadda saxla
          </button>
          <button className="btn btn-secondary" onClick={handleCancel}>
            İmtina
          </button>
        </div>
      </form>
      {isSubmitted && (
        <Stack sx={{ width: '100%', marginTop: '10px' }}>
          <Alert>Quizbank redaktə olundu!</Alert>
        </Stack>
      )}
    </DefaultLayout>
  );
}

export default UpdateQuizBank;
