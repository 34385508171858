import React from 'react';
import { HeaderBar } from '../components/HeaderBar';
import { NavigationBar } from '../components/NavigationBar';
/**
 * Default layout
 */
export class DefaultLayout extends React.Component<any> {
  render() {
    return (
      <React.Fragment>
        <HeaderBar />
        <NavigationBar content={this.props.sidebarContent} />
        <main className="main-container">{this.props.children}</main>
      </React.Fragment>
    );
  }
}
